<template>
  <div class="nav-feed-container">
    <div class="nav-feed-header-container">
      <header>News Feed</header>
      <b-button variant="link" class="header-button" @click.stop="markAllAsRead()">Mark all as read</b-button>
      <button type="button" aria-label="close feed button" class="close" @click="closeFeed">×</button>
    </div>
    <div class="nav-feed-card-container">
      <div v-if="feedNotifications.length > 0">
        <b-card
          v-for="(notification, index) in feedNotifications"
          :key="index"
          :class="['hoverable-card', 'nav-feed-card-content', notificationStyle(notification.type, notification.status)]"
          @click="logNewsFeedInteraction(notification.accounts_feed_notification_id, notification.link, notification.feed_notification_id, notification.status)
                  handleCardClick(notification.link)"
        >
          <div class="nav-feed-card-top">
            <b-img v-if="hasImage(notification.image_name)" :src="`/images/navFeed/${validImage(notification.image_name)}.svg`" style="padding-top: .25rem" />
            <div class="nav-feed-card-top-content">
              <div class="nav-feed-card-title-content">
                <header>{{ notification.title }}</header>
              </div>
              <div class="nav-feed-card-text">
                {{ notification.text }}
              </div>
            </div>
          </div>
          <div class="nav-feed-card-bottom">
            <div v-if="notification.status !== 'read'">
              <b-button variant="link" class="mark-as-button" @click.stop="updateStatus(notification.accounts_feed_notification_id, 'read')">
                Mark as read
              </b-button>
            </div>
            <div v-else>
              <b-button variant="link" class="mark-as-button--alt" @click.stop="updateStatus(notification.accounts_feed_notification_id, 'unread')">
                Mark as unread
              </b-button>
            </div>
            <div class="nav-feed-time-since">
              {{ notification.time_since }}
            </div>
          </div>
        </b-card>
      </div>
      <div v-else>
        <b-card :class="['nav-feed-card-content', notificationStyle('none')]">
          <div class="nav-feed-card-text">
            <em>No new notifications</em>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import { validFileNames } from '@/utils/valid_feed_images'
import { createOrFindClientInteractionLog } from '@/common/modules/clientInteractionLog'
import _ from 'lodash'

export default {
  name: 'NavFeed',
  mixins: [
    makeToastMixin,
  ],

  data() {
    return {
      validTypes: [
        null,
        'danger',
        'warning',
        'highlight',
        'none',
      ],
      isLogging: false,
    }
  },

  computed: {
    ...mapGetters('account', ['account']),
    ...mapGetters('website', ['website']),
    ...mapGetters('paymentMethods', ['automatedClearingHouses']),
    ...mapGetters('feed', ['feedNotifications']),
  },

  watch: {
    automatedClearingHouses: function(newVal) {
      if (newVal.length > 0) {
        const achNotification = this.feedNotifications.find(notification => notification.title === 'ACH NOW AVAILABLE')
        if (achNotification) {
          this.updateStatus(achNotification.accounts_feed_notification_id, 'read')
        }
      }
    },
  },

  methods: {
    ...mapActions('feed', ['refreshFeedNotifications', 'updateNotificationStatus', 'markAllNotificationsAsRead']),

    hasImage(imageName) {
      return imageName !== null
    },
    validImage(fileName) {
      if (validFileNames.includes(fileName)) {
        return fileName
      } else {
        return 'bell'
      }
    },
    notificationStyle(type, status) {
      if (status === 'read') {
        return `nav-feed-card-content--read`
      } else if (this.validTypes.includes(type)) {
        return `nav-feed-card-content--${type}`
      }
    },
    handleCardClick(link) {
      if (this.$route.path !== link) {
        this.$router.push(link)
      }
    },
    async updateStatus(notificationId, notificationStatus) {
      try {
        await this.updateNotificationStatus({
          notificationId: notificationId,
          notificationStatus: notificationStatus,
        })
      } catch (error) {
        console.error(error)
        this.errorToast('Error', 'Error updating feed notification status')
      }
    },
    async markAllAsRead() {
      try {
        await this.markAllNotificationsAsRead()
      } catch (error) {
        console.error(error)
        this.errorToast('Error', 'Error updating news feed')
      }
    },
    closeFeed() {
      this.$emit('close-feed')
    },
    logNewsFeedInteraction: _.debounce(async function(account_feed_notification_id, location, feed_notification_id, status) {
      if (this.isLogging || location === null) {
        return
      }

      this.isLogging = true

      try {
        await createOrFindClientInteractionLog({
          category: 'page-interaction',
          subCategory: location,
          interaction: {
            account_feed_notification: account_feed_notification_id,
            type: 'button',
            action: 'redirect',
            name: 'news-feed-interaction',
            feed_notification: feed_notification_id,
            status: status,
          },
          incompleteLogFromToday: true,
        })
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') { console.error(error) }
      } finally {
        this.isLogging = false
      }
    }, 1000),
  },
}
</script>

<style scoped lang="scss">
  .nav-feed-container {
    display: flex;
    width: 317px;
    min-height: 100px;
    max-height: 450px;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    gap: 0.5rem;

    .nav-feed-header-container {
      position: sticky;
      top: 0;
      z-index: 1;
      display: flex;
      background-color: #fff;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      padding-inline: 5%;
      padding-bottom: .5rem;

      .header-button {
        font-size: 0.75em;
        padding: 0;
      }
    }

    header{
      color: #363636;
      text-align: left;
      font-size: .875em;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
    }

    .nav-feed-card-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      align-self: stretch;

      .hoverable-card:hover {
        cursor: pointer;
        filter: brightness(95%);
      }

      .nav-feed-card-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90%;
        margin: 0.5rem 1rem;
        align-self: stretch;

        .card-body {
          padding: .5rem 1rem 1rem 1rem;
        }

        --nav-feed-card-bg: #FFF;
        --nav-feed-card-border: 1px solid #DED9DA;

        background-color: var(--nav-feed-card-bg);
        border: var(--nav-feed-card-border);

      }

      .nav-feed-card-content--warning {

        --nav-feed-card-bg: #ffeeba;
        --nav-feed-card-border-color: #ffc107;
        --nav-feed-card-text-color: #533f03;
      }

      .nav-feed-card-content--danger {

        --nav-feed-card-bg: #f8d7da;
        --nav-feed-card-border-color: #dc3545;
        --nav-feed-card-text-color: #762626;
      }

      .nav-feed-card-content--highlight {

        --nav-feed-card-bg: #F2FBFA;
        --nav-feed-card-border-color: #A6E9E1;
        --nav-feed-card-text-color: #1B4646;
      }

      .nav-feed-card-content--read {

        --nav-feed-card-bg: #F3F3F3;
        --nav-feed-card-border-color: #B2B2B2;
        --nav-feed-card-text-color: #76787A;
      }

      .nav-feed-card-content--warning,
      .nav-feed-card-content--danger,
      .nav-feed-card-content--highlight,
      .nav-feed-card-content--read, {

        header {
          color: var(--nav-feed-card-text-color);
        }

        .nav-feed-card-text {
          color: var(--nav-feed-card-text-color) !important;
        }

        background-color: var(--nav-feed-card-bg);
        border-color: var(--nav-feed-card-border-color);
      }

      .nav-feed-card-top {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 1rem;

        .nav-feed-card-top-content {
          display: flex;
          flex-direction: column;


          .nav-feed-card-title-content {
            display: flex;
            justify-content: left;
            gap: 0.5rem;
          }
        }
      }

      .nav-feed-card-text {
        color: #363636;
        text-align: left;
        font-size: 0.75em;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .nav-feed-card-bottom {
      border-top: 1px solid #C0C0C0;
      margin-top: 1rem;
      margin-bottom: -.5rem;
      display: flex;
      align-items: baseline;
      justify-content: space-between;

      .mark-as-button {
        font-size: .75em;
        margin-top: -.1em;
        padding: 0 !important;

        &--alt {
          @extend .mark-as-button;
          color: #76787A !important;
        }
      }

      .nav-feed-time-since {
        text-align: left;
        font-size: .75em;
        margin-top: -.25em;
        color: #949998;
      }
    }
  }
</style>
