<template>
  <div v-if="unread || configurationNeededCount" class="d-flex flex-row justify-content-start align-items-center">
    <div class="fa-stack menu-item-icon" style="position: relative; top: 2px;">
      <feather-icon :type="iconType" class="fa-stack-2x mr-2" />
      <fa-icon
        v-if="configurationNeededCount > 0"
        class="fa-stack-1x circle-configuration-needed"
        icon="circle"
        size="xs"
      />
      <fa-icon
        v-else-if="unread && configurationNeededCount === 0"
        class="fa-stack-1x circle-unread"
        icon="circle"
        size="xs"
      />
    </div>
    <span>
      {{ displayText }}
    </span>
  </div>
  <div v-else class="d-flex flex-row justify-content-start align-items-center">
    <span class="menu-item-icon">
      <feather-icon :type="iconType" class="mr-2" />
    </span>
    <span>
      {{ displayText }}
    </span>
  </div>
</template>

<script>
import FeatherIcon from '@/components/shared/FeatherIcon'

export default {
  name: "CtMenuItem",
  components: {
    FeatherIcon,
  },
  props: {
    displayText: {
      type: String,
      default: 'Menu Item',
    },
    iconType: {
      type: String,
      default: 'globe',
    },
    unread: {
      type: [Number, Boolean],
      default: false,
    },
    configurationNeededCount: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style lang="scss" scoped>
  .circle-unread {
    color: #dc3545;
    right: 0.5rem;
    bottom: 0.5rem;
  }

  .circle-configuration-needed {
    color: #FF9800;
    right: 0.5rem;
    bottom: 0.5rem;
  }

  .menu-item-icon {
    width: 40px;
  }
</style>
