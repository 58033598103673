var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-navbar-nav",
    {
      staticClass: "nav-feed-dropdown-component",
      staticStyle: {
        display: "flex",
        "justify-content": "center",
        width: "100%",
      },
      attrs: { type: "dark", variant: "dark" },
    },
    [
      _c(
        "div",
        { staticClass: "nav-icon-label", on: { click: _vm.toggleFeed } },
        [
          _vm.isFeedVisible && _vm.displayIsMobile
            ? _c("div", { staticClass: "indicator" })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "feed-items-icon" },
            [
              _c("b-img", { attrs: { src: "/images/navFeed/rssFeed.svg" } }),
              _vm.feedUnreadCount > 0
                ? _c("div", { ref: "feed-count", staticClass: "feed-count" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.feedUnreadCount) + "\n      "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm.isFeedVisible
        ? _c(
            "div",
            { staticClass: "feed-popup" },
            [
              _c("nav-feed", {
                on: {
                  "close-feed": function ($event) {
                    _vm.isFeedVisible = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }