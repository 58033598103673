var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.unread || _vm.configurationNeededCount
    ? _c(
        "div",
        {
          staticClass:
            "d-flex flex-row justify-content-start align-items-center",
        },
        [
          _c(
            "div",
            {
              staticClass: "fa-stack menu-item-icon",
              staticStyle: { position: "relative", top: "2px" },
            },
            [
              _c("feather-icon", {
                staticClass: "fa-stack-2x mr-2",
                attrs: { type: _vm.iconType },
              }),
              _vm.configurationNeededCount > 0
                ? _c("fa-icon", {
                    staticClass: "fa-stack-1x circle-configuration-needed",
                    attrs: { icon: "circle", size: "xs" },
                  })
                : _vm.unread && _vm.configurationNeededCount === 0
                ? _c("fa-icon", {
                    staticClass: "fa-stack-1x circle-unread",
                    attrs: { icon: "circle", size: "xs" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("span", [_vm._v("\n    " + _vm._s(_vm.displayText) + "\n  ")]),
        ]
      )
    : _c(
        "div",
        {
          staticClass:
            "d-flex flex-row justify-content-start align-items-center",
        },
        [
          _c(
            "span",
            { staticClass: "menu-item-icon" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { type: _vm.iconType },
              }),
            ],
            1
          ),
          _c("span", [_vm._v("\n    " + _vm._s(_vm.displayText) + "\n  ")]),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }